<template>
<div id="main">
    <section>
        <form method="post" action="#">
            <div class="fields">
                <div class="field">
                    <label for="name">Nom</label>
                    <input type="text" name="name" id="name" />
                </div>
                <div class="field">
                    <label for="email">Email</label>
                    <input type="text" name="email" id="email" />
                </div>
                <div class="field">
                    <label for="message">Message</label>
                    <textarea name="message" id="message" rows="3"></textarea>
                </div>
            </div>
            <ul class="actions">
                <li><input type="submit" value="Envoyer le message" /></li>
            </ul>
        </form>
    </section>
<section class="split contact">
    <section class="alt">
        <h3>Addresse</h3>
        <p>Boucle de la lèze<br />
        31860 Labarthe sur lèze</p>
    </section>
    <section>
        <h3>Tel</h3>
        <p><a href="#">(000) 000-0000</a></p>
    </section>
    <section>
        <h3>Email</h3>
        <p><a href="#">labo.binette31@gmail.com</a></p>
    </section>
    <section>
        <h3>Social</h3>
        <ul class="icons alt">
            <li><a href="#" class="icon brands alt fa-twitter"><span class="label">Twitter</span></a></li>
            <li><a href="#" class="icon brands alt fa-facebook-f"><span class="label">Facebook</span></a></li>
            <li><a href="#" class="icon brands alt fa-instagram"><span class="label">Instagram</span></a></li>
            <!--<li><a href="#" class="icon brands alt fa-github"><span class="label">GitHub</span></a></li>-->
        </ul>
    </section>
</section>
</div>
</template>

<style scoped>
				.contact {
					display: -moz-flex;
					display: -webkit-flex;
					display: -ms-flex;
					display: flex;
					-moz-align-items: center;
					-webkit-align-items: center;
					-ms-align-items: center;
					align-items: center;
					padding: 3.15rem 4rem;
				}

				.split.contact {
						margin-bottom: 0;
					}

				.split.contact {
						-moz-flex-shrink: 0;
						-webkit-flex-shrink: 0;
						-ms-flex-shrink: 0;
						flex-shrink: 0;
						-moz-flex-grow: 0;
						-webkit-flex-grow: 0;
						-ms-flex-grow: 0;
						flex-grow: 0;
						width: 6rem;
					}

				.split.contact {
						-moz-flex-shrink: 1;
						-webkit-flex-shrink: 1;
						-ms-flex-shrink: 1;
						flex-shrink: 1;
						-moz-flex-grow: 1;
						-webkit-flex-grow: 1;
						-ms-flex-grow: 1;
						flex-grow: 1;
					}

					.split.contact > section:first-child {
						padding: 4rem 4rem 3rem 4rem;
					}

					.split.contact > section:last-child {
						padding: 3rem 4rem 4rem 4rem;
					}

					.split.contact > section.alt {
						-moz-align-items: -moz-flex-start;
						-webkit-align-items: -webkit-flex-start;
						-ms-align-items: -ms-flex-start;
						align-items: flex-start;
					}

					.split.contact > section.alt > :last-child {
							margin-top: -0.325rem;
						}
	form {
		margin: 0 0 2rem 0;
	}

		form > :last-child {
			margin-bottom: 0;
		}

		form > .fields {
			display: -moz-flex;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			-moz-flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			width: calc(100% + 3rem);
			margin: -1.5rem 0 2rem -1.5rem;
		}

			form > .fields > .field {
				-moz-flex-grow: 0;
				-webkit-flex-grow: 0;
				-ms-flex-grow: 0;
				flex-grow: 0;
				-moz-flex-shrink: 0;
				-webkit-flex-shrink: 0;
				-ms-flex-shrink: 0;
				flex-shrink: 0;
				padding: 1.5rem 0 0 1.5rem;
				width: calc(100% - 1.5rem);
			}

				form > .fields > .field.half {
					width: calc(50% - 0.75rem);
				}

				form > .fields > .field.third {
					width: calc(100%/3 - 0.5rem);
				}

				form > .fields > .field.quarter {
					width: calc(25% - 0.375rem);
				}

		@media screen and (max-width: 480px) {

			form > .fields {
				width: calc(100% + 3rem);
				margin: -1.5rem 0 2rem -1.5rem;
			}

				form > .fields > .field {
					padding: 1.5rem 0 0 1.5rem;
					width: calc(100% - 1.5rem);
				}

					form > .fields > .field.half {
						width: calc(100% - 1.5rem);
					}

					form > .fields > .field.third {
						width: calc(100% - 1.5rem);
					}

					form > .fields > .field.quarter {
						width: calc(100% - 1.5rem);
					}

		}

	label {
		display: block;
		font-family: "Source Sans Pro", Helvetica, sans-serif;
		font-weight: 900;
		line-height: 1.5;
		letter-spacing: 0.075em;
		font-size: 0.8rem;
		text-transform: uppercase;
		margin: 0 0 0.75rem 0;
	}

		@media screen and (max-width: 980px) {

			label {
				font-size: 0.9rem;
			}

		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: transparent;
		border-radius: 0;
		border: solid 2px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1rem;
		text-decoration: none;
		width: 100%;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

	select {
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: 3rem;
		padding-right: 3rem;
		text-overflow: ellipsis;
	}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 3rem;
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;
	}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			text-decoration: none;
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			letter-spacing: 0;
			font-family: "Merriweather", Georgia, serif;
			text-transform: none;
			font-weight: 300;
			padding-left: 2.8rem;
			padding-right: 1rem;
			position: relative;
		}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				border-radius: 0;
				border: solid 2px;
				content: '';
				display: inline-block;
				font-size: 0.8rem;
				height: 1.8rem;
				left: 0;
				line-height: 1.65rem;
				position: absolute;
				text-align: center;
				top: -0.125rem;
				width: 1.8rem;
			}

		input[type="checkbox"]:checked + label:before,
		input[type="radio"]:checked + label:before {
			content: '\f00c';
		}

	input[type="checkbox"] + label:before {
		border-radius: 0;
	}

	input[type="radio"] + label:before {
		border-radius: 100%;
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	label {
		color: #212931;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		border-color: #eeeeee;
	}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			border-color: #18bfef;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23eeeeee' /%3E%3C/svg%3E");
	}

		select option {
			background-color: #ffffff;
			color: #212931;
		}

	.select-wrapper:before {
		color: #eeeeee;
	}

	input[type="checkbox"] + label,
	input[type="radio"] + label {
		color: #212931;
	}

		input[type="checkbox"] + label:before,
		input[type="radio"] + label:before {
			border-color: #eeeeee;
		}

	input[type="checkbox"]:checked + label:before,
	input[type="radio"]:checked + label:before {
		background-color: #212931;
		border-color: #212931;
		color: #ffffff;
	}

	input[type="checkbox"]:focus + label:before,
	input[type="radio"]:focus + label:before {
		border-color: #18bfef;
	}

	::-webkit-input-placeholder {
		color: #909498 !important;
	}

	:-moz-placeholder {
		color: #909498 !important;
	}

	::-moz-placeholder {
		color: #909498 !important;
	}

	:-ms-input-placeholder {
		color: #909498 !important;
	}

	.formerize-placeholder {
		color: #909498 !important;
	}




</style>